import createLucideIcon from '../createLucideIcon';

/**
 * @component @name CloudMoon
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTMgMTZhMyAzIDAgMSAxIDAgNkg3YTUgNSAwIDEgMSA0LjktNloiIC8+CiAgPHBhdGggZD0iTTEwLjEgOUE2IDYgMCAwIDEgMTYgNGE0LjI0IDQuMjQgMCAwIDAgNiA2IDYgNiAwIDAgMS0zIDUuMTk3IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/cloud-moon
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const CloudMoon = createLucideIcon('CloudMoon', [
  ['path', { d: 'M13 16a3 3 0 1 1 0 6H7a5 5 0 1 1 4.9-6Z', key: 'p44pc9' }],
  ['path', { d: 'M10.1 9A6 6 0 0 1 16 4a4.24 4.24 0 0 0 6 6 6 6 0 0 1-3 5.197', key: '16nha0' }],
]);

export default CloudMoon;
